import Cookies from 'js-cookie';

function userAuthentication() {
    //const response = await fetch('https://min.eignamork.is/api/login/check');
    //const response = await fetch('https://testimonialapi.toolcarton.com/api');
    // const myJson = await response.json();
    // console.log(myJson[0].name)
    var kaka = Cookies.get("__session")
    //const myJson = await response.json(); //extract Object from the http response
    //return Object.keys(myJson).length == 0;
    //return !(kaka == undefined)
    return kaka;
}

export default userAuthentication;