import { React, Fragment } from "react";
import Userfront from "@userfront/react";

import { Route, Redirect, useHistory } from "react-router-dom";
import { FaUserAlt } from 'react-icons/fa';
import { Navbar, Container, Nav, NavDropdown } from 'react-bootstrap';
import { BsQuestionCircle } from 'react-icons/bs';
import Cookies from "js-cookie";
import userAuthentication from "../AuthenticationFunction";


import eignamorkLogoLarge from '../images/eignamork.png';
import eignamorkLogoSmall from '../images/eignamork_small.png';

const NavigationBar = () => {
    const navDropdownTitle = (<FaUserAlt />);
    const history = useHistory();

    function LogoutRedirect(e){
        e.preventDefault();
        Cookies.remove("__session");
        history.push("/login");
    }

    function renderFn({ location }) {
        // If the user is not logged in, redirect to login
        if (!userAuthentication()) {
            return (
                <Fragment>
                    <Navbar bg="light" variant="light">
                        <Container>
                            <Navbar.Brand href="/">
                                <img id="largeLogo" src={eignamorkLogoLarge} alt="Eignamörk Logo" />
                                <img id="smallLogo" src={eignamorkLogoSmall} alt="Eignamörk Logo Small" />
                            </Navbar.Brand>
                            <Nav className="me-auto">
                                <NavDropdown title={navDropdownTitle} id="nav-dropdown">
                                    <NavDropdown.Item href="/login">Innskráning</NavDropdown.Item>
                                    <NavDropdown.Item href="/sign-up">Stofna aðgang</NavDropdown.Item>
                                </NavDropdown>
                            </Nav>
                        </Container>
                    </Navbar>
                </Fragment>
            );
        }

        // If the user is logged in, show the dashboard
        // þarf að ná í username, veit ekki hvar það er gert
        const userData = JSON.stringify(Userfront.user, null, 2);
        const parsedUserData = JSON.parse(userData);

        return (
            <Fragment>
                <Navbar bg="light" variant="light">
                    <Container>
                        <Navbar.Brand href="/">
                            <img id="largeLogo" src={eignamorkLogoLarge} alt="Eignamörk Logo" />
                            <img id="smallLogo" src={eignamorkLogoSmall} alt="Eignamörk Logo Small" />
                        </Navbar.Brand>
                        <Nav className="me-auto">
                            <Nav.Link href="/landeignir">Landeignir</Nav.Link>
                            <Nav.Link href="/skjol">Skjöl</Nav.Link>
                            <NavDropdown title="Kortasjár" id="nav-dropdown">
                                <NavDropdown.Item href="/grannar">Finna nágrannajarðir</NavDropdown.Item>
                                <NavDropdown.Item href="/teikna">Teikna eignamörk</NavDropdown.Item>
                            </NavDropdown>
                            <NavDropdown title={navDropdownTitle} id="nav-dropdown">
                                <p className="mt-1 text-center">{parsedUserData["name"]}</p>
                                <NavDropdown.Item onClick={LogoutRedirect}>Útskrá</NavDropdown.Item>
                            </NavDropdown>
                            <a className="m-2" href="https://www.eignamork.is" target="_blank" rel="noreferrer"><BsQuestionCircle /></a>
                        </Nav>
                    </Container>
                </Navbar>
            </Fragment>
        )
    }

    return <Route render={renderFn} />;
}

export default NavigationBar;