// "http://localhost:4000/api" 


const _getAPIPath = () => {
    if (process.env.NODE_ENV === 'development') {
        return 'http://localhost:4000/api'
    } else {
        return '/api'
    }
}

export default {
    getAPIPath: _getAPIPath // "http://localhost:4000/api"  // process.env.getAPIPath()  || "http://localhost:4000/api"
}
