import React, { Component } from 'react';
import Userfront from "@userfront/react";
import Alert from "./Alert";
import islandLogo from '../images/Island_is_merki-1536x464.png';

// Define the Login form component
class LoginForm extends Component {
    constructor(props) {
        super(props);
        this.state = {
            emailOrUsername: "",
            password: "",
            alertMessage: ""
        };

        this.handleInputChange = this.handleInputChange.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
        this.setAlertMessage = this.setAlertMessage.bind(this);
    }

    handleInputChange(event) {
        event.preventDefault();
        const target = event.target;
        this.setState({
            [target.name]: target.value,
        });
    }

    handleSubmit(event) {
        event.preventDefault();

        // Reset the alert to empty
        this.setAlertMessage();

        Userfront.login({
            method: "password",
            emailOrUsername: this.state.emailOrUsername,
            password: this.state.password,
        }).catch((error) => {
            this.setAlertMessage(error.message);
        });
    }

    setAlertMessage(message) {
        this.setState({ alertMessage: message });
    }

    render() {
        return (
            <div className="formDiv">
                <h2 className="mb-3">Innskráning</h2>
                <a href="https://innskraning.island.is/?id=min.eignamork.is">
                <button className="btn btn-primary">Skrá inn</button>
                </a>
                
            </div>
        );
    }
}

export default LoginForm;