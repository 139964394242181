import React, { Fragment, useState, useEffect } from "react";
import { Route, Redirect } from "react-router-dom";
import ReactLoading from "react-loading";
import userAuthentication from "../AuthenticationFunction";
import Cookies from 'js-cookie';
import config from "../components/Config";


const MapDraw = () => {
    const [owner, setOwner] = useState("");

    useEffect(() => {
        try {
            // If the user is logged in, show the dashboard
            // þarf að ná í username, veit ekki hvar það er gert
            // const userData = JSON.stringify(Userfront.user, null, 2);
            // const parsedUserData = JSON.parse(userData);
            //const userID = parsedUserData.data["eigandiID"];

            const kaka = Cookies.get("__session")


            if (kaka) {
                fetch(`${config.getAPIPath()}/owners/${kaka}`, {
                    method: "GET",
                    headers: {
                        "Content-Type": "application/json"
                    },
                }).then(async (response) => {
                    const jsonData = await response.json();
                    console.log(jsonData)
                    setOwner(jsonData);
                }).catch((err) => {
                    console.log(err.message);
                })
            }
        } catch (err) {
            console.error(err.message);
        }
    }, [])

    function renderFn({ location }) {
        // If the user is not logged in, redirect to login
        if (!userAuthentication()) {
            return (
                <Redirect
                    to={{
                        pathname: "/login",
                        state: { from: location },
                    }}
                />
            );
        }


        if (!(owner === "")) {
            if (!owner.leyfiteikna) {
                return (
                    <Fragment>
                        <div className="container my-5">
                            <h3 className="mb-3">Aðgangur læstur</h3>
                            <p>Því miður ert þú ekki með aðgang að þessu svæði.</p>
                            <p>Hægt er að kaupa aðgang á <a href="https://eignamork.is/landeignamork/thjonusta/" target="_blank" rel="noreferrer">eignamork.is</a></p>
                        </div>
                    </Fragment>
                )
            } else {
                return (
                    <Fragment>
                        <iframe title="iframe" style={{ borderStyle: "none", width: '100vw', height: '90vh' }} src="/topo-draw/index.html"></iframe>
                    </Fragment >
                )
            }
        }

        return (
            <div className="h-100 d-flex flex-row justify-content-center align-items-center mt-5">
                <ReactLoading className="mt-5" type={"spokes"} color={"black"} />
            </div>
        )
    }

    return <Route render={renderFn} />;
}

export default MapDraw;