import React, { Fragment } from "react";
import { Route, Redirect } from "react-router-dom";
import userAuthentication from "../AuthenticationFunction";

import welcomeImage from '../images/hus.jpeg';

const Welcome = () => {
    function renderFn({ location }) {

        //console.log(userAuthentication())
        // If the user is not logged in, redirect to login
        if (!userAuthentication()) {
            return (
                <Redirect
                    to={{
                        pathname: "/login",
                        state: { from: location },
                    }}
                />
            );
        }

        return (
            <Fragment>
                <div className="d-flex flex-row justify-content-center my-5">
                    <div className="imageContainer">
                        <img className="mx-5" id="housesImage" src={welcomeImage} alt="Houses" />
                    </div>
                    <div>
                        <h2 >Velkomin!</h2>
                        <p>Velkomin/n á innri vef min.eignamörk.is. Hér getur notandi skoðað og breytt upplýsingum um sína landeign, skoðað skjöl um landeignir og nágrannalandeignir.</p>
                        <p>Á þessum vef má einnig finna kortavefsjárnar okkar, bæði til að finna sína nágranna og einnig til að teikna upp sína landeign á korti.</p>
                        <p>Frekari leiðbeiningar er hægt að finna á <u><a href="https://www.eignamork.is" target="_blank" rel="noreferrer">þessari síðu</a></u>.</p>
                    </div>
                </div>
            </Fragment>
        )
    }

    return <Route render={renderFn} />;
}

export default Welcome;