import React from "react";

var style = {
    backgroundColor: "#F8F8F8",
    borderTop: "1px solid #E7E7E7",
    padding: "20px",
    position: "fixed",
    left: "0",
    bottom: "0",
    height: "100px",
    width: "100%",
}

var phantom = {
    display: 'block',
    padding: '20px',
    height: '60px',
    width: '100%',
}

const Footer = () => {
    return (
        <div>
            <div style={phantom} />
            <div style={style}>
                <p className="text-center">
                    <a className="m-2" href="https://www.eignamork.is/hjalp" target="_blank" rel="noreferrer"><u>Hafa samband</u></a>
                </p>
                <div className="text-muted">
                    <p className="text-center">
                        &copy;{new Date().getFullYear()} Topolocate ehf. - Allur réttur áskilinn
                    </p>
                </div>
            </div>
        </div >
    );
}

export default Footer;