import './App.css';
import { React, Fragment } from 'react';
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";

// components
import Welcome from "./components/Welcome";
import LandsList from "./components/LandsList";
import NavigationBar from "./components/NavigationBar";
import PdfList from "./components/PdfList";
import MapNeighbors from "./components/MapNeighbors";
import MapDraw from "./components/MapDraw";
import Footer from "./components/Footer";
import SignupForm from "./components/SignupForm";
import LoginForm from "./components/LoginForm";

function App() {
  return (
    <Fragment>
      <div className="container-xxl">
        <Router>
          <div>
            <Switch>
              <Route path="/login">
                <NavigationBar />
                <div className="container">
                  <Login />
                </div>
                <Footer />
              </Route>
              <Route path="/grannar">
                <NavigationBar />
                <MapNeighbors />
              </Route>
              <Route path="/teikna">
                <NavigationBar />
                <MapDraw />
              </Route>
              <Route path="/landeignir">
                <NavigationBar />
                <div className="container">
                  <LandsList />
                </div>
                <Footer />
              </Route>
              <Route path="/sign-up">
                <NavigationBar />
                <div className="container">
                  <SignUp />
                </div>
                <Footer />
              </Route>
              <Route path="/skjol">
                <NavigationBar />
                <div className="container">
                  <PdfList />
                </div>
                <Footer />
              </Route>
              <Route path="/">
                <NavigationBar />
                <div className="container">
                  <Welcome />
                </div>
                <Footer />
              </Route>
            </Switch>
          </div>
        </Router>
      </div >
    </Fragment >
  );
}

function SignUp() {
  return (
    <div className="container my-5">
      <SignupForm /> 
    </div>
  );
}

function Login() {
  return (
    <div className="container my-5">
      <LoginForm />
    </div>
  );
}

export default App;
